.menu {
	position: absolute;
	top: 0;
	left: 0;
	min-width: 22%;
	height: 100%;
	z-index: 10;

	background: rgba(0, 0, 0, 0.9);

	overflow-y: auto;
	
	padding: 0 1.2rem;
}

.menu > *:last-child {
	margin-bottom: 1.2rem;
}

.menu h1 {
	font-size: 150%;

	margin: 0;
	margin-top: 0.5em;
}

.menu h2 {
	font-size: 120%;

	margin: 0;
	margin-top: 0.6em;
	margin-bottom: 0.2em;

	padding-top: 0.5rem;
	border-top: var(--thin) solid #3c3c3c;
}

.menu h1, .menu h2 {
	font-weight: normal;
}

.filters {
	margin: 0;
	padding: 0;
	list-style-type: none;
}

.filters li {
	margin: 0;
	padding: 0;
	margin-top: 0.3rem;
}

.menu .close {
	width: 100%;
	margin-top: 0.9em;
}

.pageScale {
	margin-top: 0.5em;
}

.pageScale label {
	margin-right: 0.3em;
}

.controlsHelp p {
	margin: 0;
	margin-bottom: 0.2rem;
	font-size: 1.4rem;
}
