:root {
	--cell-size: 20rem;
}

.previewWrapper {
	margin: 0.5rem;
}

.preview {
	width: 100%;
	height: 100%;

	position: relative;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	max-width: var(--cell-size);
	max-height: var(--cell-size);
	box-sizing: border-box;
}

.previewBackground {
	position: absolute;
	width: 100%;
	height: 100%;
	background-size: cover;
  	background-position: center;
	z-index: -1;
}

.blur {
	-webkit-backface-visibility: hidden;
	-webkit-perspective: 1000;
	-webkit-transform: translate3d(0, 0, 0);
	-webkit-transform: translateZ(0);

	backface-visibility: hidden;
	perspective: 1000;
	transform: translate3d(0, 0, 0);
	transform: translateZ(0);

	filter: blur(0.8rem) saturate(1.5);
}

.gray {
	background-color: #222;
}

.previewImage {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.previewImage > div {
	display: block;
	width: var(--cell-size);
	min-height: 4.5rem;
	cursor: pointer;
}

.previewImage img {
	display: block;

	width: 100%;
	max-width: var(--cell-size);
	max-height: var(--cell-size);
	object-fit: contain;

	margin: auto;
}

.previewImage {
	flex-grow: 1;
}

.previewInfo {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	font-size: 1.333rem;
	text-align: center;
	color: lightgray;
	padding: var(--thin);
}

.saved .previewInfo {
	background-color: rgba(0, 127, 0, 0.7);
}

.seen {
	opacity: 0.2;
}

.minimized .previewBackground {
	display: none;
}

.minimized .previewImage {
	display: none;
}

.minimized .previewInfo {
	position: relative;
	min-width: var(--cell-size);
}

.selected {
	box-shadow: 0 0 0 0.2rem #fbff00, 0 0 0 0.3rem #ffbc00;
    border-radius: var(--thin);
}

.groupSize {
	border: 0;
	font-family: inherit;
	font-size: 90%;
	color: inherit;
	cursor: pointer;

	background: #000000b5;
    position: absolute;
    right: 0;
    top: 0;
    text-align: center;
    padding: 0.2rem 0.6rem;
	padding-left: 0.8rem;
    padding-top: 0;
    text-shadow: #000 var(--thin) var(--thin) 0;
    font-weight: bolder;
	border-radius: 0 0 0 1rem;
	z-index: 3;
}

.score.negative {
	color: #ff4040;
}

.rating.explicit {
	color: #ff4040;
}

.rating.questionable {
	color: #ffdf40;
}

.rating.safe {
	color: #40ff4f;
}
