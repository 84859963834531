html {
	--theme-color: hsl(212, 74%, 20%);
	--theme-color-dark: hsl(212, 80%, 10%);
	--theme-color-darker: hsl(212, 82%, 7%);
	--highlight-color: #1bf4ff;
	--text-color: #fff;

	font-size: 10px;

	--thin: calc(max(1px, 0.055rem));
}

body {
	margin: 0;

	font-size: 1.6rem;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

	color: var(--text-color);
	background: linear-gradient(to right, var(--theme-color-dark) 0%, var(--theme-color-darker) 100%);
	scrollbar-color: var(--theme-color) var(--theme-color-dark);
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html, body, div#root {
	width: 100%;
	height: 100%;
	min-width: 100%;
	min-height: 100%;
}

a {
	color: var(--text-color);
	text-decoration: underline;
}

button, input, textarea, select {
	appearance: none;

	box-sizing: border-box;
	background-color: var(--theme-color);
	color: inherit;
	border: var(--thin) solid var(--theme-color);
	border-radius: 0.166em;

	font-family: inherit;
	font-size: 90%;
	padding: 0 0.3em 0.1em;

	box-shadow: 0 0 0.187em 0.062em rgba(0, 0, 0, 0.5);
}

button:disabled, input:disabled, select:disabled {
	background: #4e4e4e;
	color: #8a8a8a;
	border-color: #4e4e4e;
}

button {
	cursor: pointer;
}

input[type=checkbox] {
	width: 0.8em;
	height: 0.8em;
	display: inline-block;
	vertical-align: middle;
	margin-right: 0.72rem;
	margin-bottom: 0.5rem;
	cursor: pointer;
}

input[type=checkbox]:checked {
	background: radial-gradient(circle at 50%, var(--highlight-color) 0%, var(--highlight-color) 45%, var(--theme-color) 55%, var(--theme-color) 100%);
}

/* https://stackoverflow.com/a/42087251 */
select, input[list] {
	background-image: url("data:image/svg+xml;utf8,<svg fill='white' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
	background-repeat: no-repeat;
	background-size: 2.4rem;
	background-position-x: 100%;
	background-position-y: 50%;
	padding-right: 2.4rem;
}

hr {
	color: #3c3c3c;
}
