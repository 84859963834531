.blacklistEditor {
    margin-top: 0.8rem;
}

.blacklistEditor button {
    font-size: 75%;
    margin-left: 0.6rem;
    padding: 0.2rem 0.4rem;
    display: inline-block;
    vertical-align: top;
}

.blacklistEditor textarea {
    width: 100%;
    margin-top: 0.6rem;
    box-sizing: border-box;
}
