.loginForm {
	width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginForm h1 {
	margin: 0;
    margin-bottom: 1.2rem;
	text-align: center;
}

.loginForm input, .loginForm button {
	box-sizing: border-box;
	width: 100%;
	margin-top: 0.4rem;
	font-size: 110%;
	color: white;
    padding: 1.2rem;
    border: 0;
}

.loginForm input {
    background: rgba(0, 0, 0, 0.32);
}

.loginForm button {
    background: hsla(285, 39%, 56%, 0.11);
}
