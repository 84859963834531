.main {
	width: 100%;
	height: 100%;
	min-width: 100%;
	min-height: 100%;

	display: flex;
	flex-direction: column;
}

.content {
	position: relative;
	flex-grow: 1;

	padding: 0 1.2rem;

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	align-content: flex-start;

	overflow-y: scroll;
	overflow-x: hidden;
	
	color: var(--text-color);
}

.search {
	flex-shrink: 0;
	display: flex;
	flex-direction: row;
	padding: 1.2rem;
	padding-bottom: 0.6rem;
}

.search input {
	flex-grow: 1;
	font-size: 1.6rem;
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.search button, .search input {
	margin-right: 0.6rem;
}

.search :last-child {
	margin-right: 0;
}

.menuButton {
	width: 2em;
	background: url('./menu.svg') no-repeat center center;
	background-size: 90% 90%;
	color: transparent;
	border: 0;
	box-shadow: none;
	filter: drop-shadow(0 0 0.5rem #000);
}

.navigation {
	padding: 0.8rem;
	padding-top: 0.5rem;
	text-align: center;
}

.navigation button {
	margin: 0 0.3rem;
}

.navigation span {
	color: var(--text-color);
	margin: 0 0.3rem;
}

.groupViewer {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 50;

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
	color: white;
	overflow-x: hidden;
    align-content: flex-start;
	
	background-color: rgba(0, 0, 0, 0.3);
}
