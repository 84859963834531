.loading {
    width: 100%;
    height: 100%;
	display: flex;
    flex-direction: row;
	align-items: center;
	font-size: 24px;
}

.loading div {
	margin: auto;
}
