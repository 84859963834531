.overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;

	text-align: center;

	background-color: rgba(0, 0, 0, 0.7);
	color: var(--text-color);

	overflow: hidden;

	display: flex;
	flex-direction: row;
}

.closeButton, .tagHideButton, .tagShowButton {
	position: absolute;
	top: 0;
	padding: 0;
	margin: 0;
	width: 2.8rem;
	height: 2.8rem;
	z-index: 15;
	background: transparent;
	background-size: 80%;
	background-position: center;
	background-repeat: no-repeat;
	border: 0;
	cursor: pointer;
}

.tagHideButton, .tagShowButton {
	left: 0;
}

.tagHideButton {
	background-image: url(/minus.png);
}

.tagShowButton {
	background-image: url(/plus.png);
}

.closeButton {
	right: 0;
	background-image: url(/cross.png);
}

.postContent {
	flex-grow: 1;
	position: relative;
}

.postContentWrapper {
	width: 100%;
	height: 100%;
    display: flex;
}

.postContentWrapper img {
	width: 100%;
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
}

.postContentWrapper video {
	width: 100%;
    height: 100%;
}

.postControls {
	text-align: left;
	font-size: 1.333rem;
	flex-shrink: 0;
	overflow: hidden;
}

.postControls.shown {
	min-width: 22%;
	border-right: 0.2rem solid #000;
	background-color: rgba(0, 0, 0, 0.9);
	overflow-y: auto;
}

.postControls div {
	margin: 0.5rem 2rem;
}

.postControls hr {
    border: 0;
    padding: 0;
    margin: 0;
    border-top: var(--thin) solid #3c3c3c;
    margin-top: 0.8rem;
}

.customTagAdd button {
	margin-left: 0.6rem;
}

.customTagRemove {
	display: inline;
	border: 0;
	margin: 0;
	padding: 0.3rem;
	background: transparent;
	margin-right: 0.6rem;
	color: #fff;
	cursor: pointer;
}

.tag {
	text-decoration: none;
}

.customTag button {
	margin-right: 0.5rem;
}

.customTag a {
	color: #88f;
}
